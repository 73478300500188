<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Release</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <div class="col-12 ma-0 pa-0">
          <v-text-field
            v-model="formData.version"
            label="Version"
            clearable
            outlined
            dense
            :error-messages="versionErrors"
            @input="$v.formData.version.$touch()"
            @blur="$v.formData.version.$touch()"
          ></v-text-field>
        </div>
        <div class="col-12 ma-0 pa-0">
          <v-file-input
            v-model="formData.apk_file"
            show-size
            counter
            multiple
            dense
            hide-details
            outlined
            clearable
            prepend-icon="mdi-file"
            label="Apk file"
            :error-messages="apk_fileErrors"
            @input="$v.formData.apk_file.$touch()"
            @blur="$v.formData.apk_file.$touch()"
          />
        </div>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      apk_file: { required },
      version: { required },
    },
  },
  name: "AddItem",
  props: ["refresher"],
  data: () => ({
    dialog: false,
    formData: {
      apk_file: null,
      version: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },

    toggleModal() {
      if (this.dialog) this.resetCreateForm();

      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.$v.$touch();
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = new FormData();
      data.append("version", this.formData.version);
      data.append("apk_file", this.formData.apk_file[0]);
      this.formData;
      ApiService.post("/warehouse_management/wms_app_apks/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `Release has been successfully added!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        apk_file: null,
        version: null,
      };
    },
  },
  computed: {
    versionErrors() {
      return this.handleFormValidation("version", this);
    },
    apk_fileErrors() {
      return this.handleFormValidation("apk_file", this);
    },
  },
};
</script>
